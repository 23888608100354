body {
    font-family: $primaryFont;
    background: $bodyBg;
    color: $bodyFontColor;
    a {
        color: $bodyFontColor;
        transition: 0.25s all;
    }
    .backdrop {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 100;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        @media #{$medium-up} {
            display: none;
        }
    }
    .open-nav {
        width: 200px !important;
    }
}