// UP
$xsmall-up: "(min-width: 544px)";
$small-up: "(min-width: 768px)";
$medium-up: "(min-width: 992px)";
$large-up: "(min-width: 1200px)";
$xlarge-up: "(min-width: 1366px)";

// DOWN

$xsmall-down: "(max-width: 543px)";
$small-down: "(max-width: 767px)";
$medium-down: "(max-width: 991px)";
$large-down: "(max-width: 1199px)";

// BETWEEN
$xsmall-only: "(max-width: 543px)";
$small-only: "(max-width: 767px)";
$medium-only: "(min-width: 768px) and (max-width: 991px)";
$large-only: "(min-width: 992px) and (max-width: 1199px)";
$xlarge-only: "(min-width: 1200px)";


$primaryFont : 'Lato', sans-serif;
$bodyFontColor: #333333;
$bodyBg: #f5f5f7;
$gray: #fafafa;
$primaryFontColor: #138D75;
$secondaryFontColor: #795548;
$white: #ffffff;


$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}