#pageArea {
    height: 100vh;
    @media #{$medium-up} {
        width: calc(100% - 200px);
        float: right;
    }
    .page-heading {
        background-color: $primaryFontColor;
        h1 {
            color: $white;
            line-height: 50px;
            font-size: em(25);
            padding: 0 20px;
            text-transform: uppercase;
            @media #{$medium-up} {
                padding: 0 50px;
                line-height: 100px;
                font-size: em(40);
            }
        }
    }
    .home-intro {
        height: 100%;
        background: url('/asset/images/home5.JPG') no-repeat 55% 12%;
        background-size: cover;
        h2 {
            padding: 10px;
            font-size: em(20);
            background-color: rgba(0, 128, 115,0.6) !important;
            color: $white;
            display: inline-block;
            margin:20px 0 0 20px;
            .underlined-text {
                display: block;
                text-decoration: underline;
            }
            @media #{$small-up} {
                font-size: em(50);
                line-height: 55px;
                margin:50px 0 0 50px;
                text-shadow: 2px 2px #000;
            }
        }
    }
    .page-content {
        padding: 30px 25px;
        @media #{$medium-up} {
            padding: 50px;
        }
        .secondary-intro {
            color: $secondaryFontColor;
            font-size: 22px;
            line-height: 25px;
            padding-left: 20px;
            border-left: 5px solid $secondaryFontColor;
            margin-bottom: 30px;
            @media #{$medium-up} {
                font-size: 30px;
                line-height: 40px;
            }
        }
        .description {
            margin-bottom: 30px;
            font-size: em(20,16);
            line-height: 30px;
        }
    }
    .table-content {
        -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        padding: 15px;
        background-color: $white;
        margin: 25px;
        @media #{$medium-up} {
            margin: 50px auto;
            width: 70%;
            padding: 20px;
        }   
        h3 {
            color: #795548;
            font-size: 1.5625em;
            padding-bottom: 15px;
            border-bottom: 2px solid #138D75;
            margin-bottom: 10px;
            @media #{$medium-up} {
                font-size: 2em;
            }
        }
        ul {
            @media #{$medium-up} {
                margin: 0 -20px;
            }
            li {
                list-style: outside;
                margin-left: 15px;
                @media #{$medium-up} {
                    display: inline-block;
                    width: 29%;
                    margin: 1% 2%;
                    vertical-align: bottom;
                    color: $secondaryFontColor;
                    border-bottom: 2px solid #138D75;
                    padding-bottom: 8px;
                }
                span {
                    color: $primaryFontColor;
                    font-size: em(18);
                }

                br {
                    display: none;
                    @media #{$medium-up} {
                        display: block;
                    }
                }
            }
        }
    }
    .card-component {
        margin: 25px 25px;
        -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        padding: 15px 15px 0;
        background-color: $white;
        @media #{$medium-up} {
            margin: 50px auto;
            width: 70%;
            padding: 20px 20px 0;
        }
        .card-heading {
            @media #{$medium-up} {
                text-align: left;
                
            }
            img {
                margin-bottom: 5px;
                height: 25px;
                @media #{$medium-up} {
                    float: left;
                    height: 40px;
                }
                &.theme-bg {
                    background-color: #000;
                }
            }
            a {
                display: block;
                color: $primaryFontColor;
                text-decoration: none;
                font-size: em(18);
                padding-bottom: 10px;
                border-bottom: 1px solid $primaryFontColor;
                margin-bottom: 10px;
                transition: 0.25s all;
                border-bottom: 3px solid transparent;
                &:hover {
                    border-bottom: 3px solid $primaryFontColor
                }
                @media #{$medium-up} {
                    float: right;
                    font-size: em(30)
                }
            }
            h3 {
                color: $secondaryFontColor;
                margin-bottom: 20px;
                font-size: 20px;
                padding-bottom: 10px;
                border-bottom: 1px solid $primaryFontColor;
                @media #{$medium-up} {
                    margin-top: 20px;
                    
                    clear: both;
                    padding: 10px 0;
                }
            }
        }
        .card-content {
            h4 {
                margin-bottom: 10px;
                color: $secondaryFontColor;
                font-weight: 600;
                text-transform: uppercase;
            }
            ul {
                margin-bottom: 25px;
                li {
                    list-style: disc;
                    list-style-position: outside;
                    margin-bottom: 10px;
                    margin-left: 15px;
                }
            }
            p {
                margin-bottom: 15px;
            }
        }
        .card-footer {
            padding: 10px 15px;
            background: $primaryFontColor;
            margin: 0 -20px;
            color: $white;
            p {
                font-size: em(14);
            }
        }
    }
    .not-found {
        .svg-inline--fa {
            width: 40px;
            height: 40px;
            color: red;
            margin-right: 5px;
            position: relative;
            top: 10px;
        }
        a {
            color: $primaryFontColor;
            font-size: 20px;
            text-decoration: none;
            &:hover {
                border-bottom: 3px solid $primaryFontColor;
            }
            @media #{$medium-up} {
                font-size: 32px;
            }
        }
    }
    .container {
        margin: 25px;
        @media #{$medium-up} {
            margin:50px;
        }
    }
    .items-stack {
        background-color: $white;
        padding: 30px 20px;
        margin-bottom: 20px;
        @media #{$medium-up} {
            margin-bottom: 40px;
        }
        h3 {
            color: $secondaryFontColor;
            font-size: em(25);
            padding-bottom: 15px;
            margin-bottom: 25px;
            border-bottom: 1px solid $primaryFontColor;
            @media #{$medium-up} {
                font-size: em(32);
            }
        }
        h4 {
            margin-bottom: 15px;
            text-transform: uppercase;
        }
        ul {
            li {
                display: inline-block;
                margin: 0;
                text-align: center;
                margin-bottom: 20px;
                width: 33%;
                
                @media #{$small-up} {
                    margin: 0 20px;
                    width: auto;
                }
                @media #{$medium-up} {
                    margin: 0 42px;
                }
                img {
                    width: 60px;
                    margin-bottom: 15px;
                    @media #{$medium-up} {
                        width: 65px;
                    }
                }
            }
        }
        
    }
    .rating {
        .svg-inline--fa {
            color: $primaryFontColor;
            margin-right: 3px;
        }
    }
    .tile {
        background-color: $white;
        padding: 25px;
        margin-bottom: 25px;
        @media #{$small-up} {
            width:43%;
            margin-right: 3%;
            float: left;
            &:last-child {
                margin-right: 0;
            }
        }
        h3 {
            font-size: em(20);
            text-transform: uppercase;
            margin-bottom: 15px;
            @media #{$medium-up} {
                font-size: em(32);
            }
            a {
                color: $primaryFontColor;
                text-decoration: none;
                transition: 0.25s all;
                &:hover {
                    border-bottom: 3px solid $primaryFontColor
                }
            }
        }
        span {
            display: block;
            margin-bottom: 20px;
            color: $secondaryFontColor;
            font-weight: 700;
        }
        .svg-inline--fa {
            color: $primaryFontColor;
            margin-right: 5px;
        }
    }
    .contact {
        li {
            margin-bottom: 30px;
        }
        .svg-inline--fa {
            color: $primaryFontColor;
            margin-right: 15px;
            width: 50px;
            height: 50px;
            float: left;
        }
        a{
            line-height: 50px;
            vertical-align: middle;
            text-decoration: none;
            color: $secondaryFontColor;
            transition: 0.25s all;
            padding-bottom: 5px;
            &:hover {
                span {
                    border-bottom: 2px solid $secondaryFontColor
                }
            }
            span {
                font-size: em(16);
            }
        }
    }
}