nav {
    @media #{$medium-up} {
        width: 200px;
        float: left;
    }
    header {
        background-color: $gray;
        padding: em(10,28);
        -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        @media #{$medium-up} {
            padding: 30px 15px;
            position: fixed;
            width: 170px;
        }
       
        a {
            font-size: em(22);
            color: $primaryFontColor;
            line-height: em(22,22);
            text-align: center;
            text-decoration: none;
            display: block;
            span {
                display: block;
                font-size: em(15,22);
                color: $secondaryFontColor;
            }
            @media #{$medium-up} {
                font-size: em(30);
                span {
                    font-size: em(18,30);
                }
            }
        }
            
        
        .hamburger {
            font-size: 35px;
            color: $primaryFontColor;
            float: left;
            margin-top: 5px;
            margin-left: 20px;
            @media #{$medium-up} {
                display: none;
            }
        }
    }
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 101;
        top: 0;
        -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        @media #{$medium-up} {
            top: 120px;
            width: 200px !important;
        }
        left: 0;
        background-color: $white;
        overflow-x: hidden;
        transition: 0.5s;
        button.closebtn {
            background: transparent;
            border: none;
            font-size: 35px;
            float: right;
            @media #{$medium-up} {
                display: none;
            }
        }
        
        .svg-inline--fa {
            margin-right: 10px;
            width: 25px;
            height: 25px;
            position: relative;
            top: 5px;
        }
        ul {
            clear: both; 
            li {
                .button-wrapper {
                    padding: 0 10px 0 20px;
                    transition: 0.3s;
                    &:hover, &.active {
                        color: $primaryFontColor;
                        background: #f6f6f6;
                        border-left: 4px solid $primaryFontColor;
                        .fa-user, .fa-signal, .fa-chart-bar, .fa-user-graduate, .fa-address-card, .fa-file-alt{
                            color: #FF5733;
                        }
                    }
                    .fa-file-alt {
                        float: left;
                        color: #330099;
                        position: relative;
                        top: 18px;
                        
                    }
                    button.resume-link {
                        background: transparent;
                        border: none;
                        font-size: 35px;
                        font-size: 1em;
                        line-height: 60px;
                        width: 130px;
                        text-align: left;
                    }
                }
            }
        }
        a {
            text-decoration: none;
            font-size: em(16);
            display: block;
            transition: 0.3s;
            line-height: 60px;
            padding: 0 10px 0 20px;
            &:hover, &.active {
                color: $primaryFontColor;
                background: #f6f6f6;
                border-left: 4px solid $primaryFontColor;
                .fa-user, .fa-signal, .fa-chart-bar, .fa-user-graduate, .fa-address-card, .fa-file-alt{
                    color: #FF5733;
                }
            }
            &.closebtn {
                text-align: right;
                font-size: 36px;
                margin-right: 10px;
                @media #{$medium-up} {
                    display: none;
                }
            }
            
            .fa-user{
                color: #669933;
            }
            .fa-signal {
                color: #9933FF;
            }
            .fa-chart-bar {
                color: #66CCFF;
            }
            .fa-user-graduate {
                color: #6600CC;
            }
            .fa-address-card {
                color: #990000;
            }
        }
    }
}


  
  
